/* eslint-disable  */
import { motion } from "framer-motion";
import React from "react";

function HeroSectionContent({
  sectionData,
  data,
}: {
  data: any;
  sectionData: any;
}) {
  return (
    <motion.div
      className={`flex w-full shrink-0 grow-0 flex-col items-center justify-start gap-6 px-6 pb-2 ${
        sectionData.id === data[0].id ? "pt-[24px]" : "pt-0"
      }`}
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0.5 }}
      transition={{ duration: 5 }}
      style={{ borderRadius: `20px` }}
    >
      <div className="relative flex shrink-0 grow-0 flex-col items-center justify-start gap-4 bg-white">
        {sectionData.headline && (
          <p className="w-full shrink-0 grow-0 text-center text-[28px] font-bold leading-9 text-[#38465b]	">
            {sectionData.headline}
          </p>
        )}
        {sectionData.description && (
          <p className="w-full shrink-0 grow-0 text-center text-sm text-[#585966]">
            {sectionData.description}
          </p>
        )}

        {sectionData.ctaText && (
          <a
            href={sectionData.ctaUrl}
            className="relative flex shrink-0 grow-0 items-center justify-center gap-2.5 rounded-[100px] bg-[#EFEFEF] px-4 py-2"
          >
            <p className="shrink-0 grow-0 text-left text-sm font-medium text-[#38465B]">
              {sectionData.ctaText}
            </p>
          </a>
        )}
      </div>
    </motion.div>
  );
}

export default HeroSectionContent;
